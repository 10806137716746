<template>
  <div>
    <!-- 面包屑 -->
    <el-breadcrumb separator="/">
      <!-- <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item> -->
      <el-breadcrumb-item>项目管理</el-breadcrumb-item>
      <el-breadcrumb-item>项目列表</el-breadcrumb-item>
    </el-breadcrumb>
    <!-- 卡片区 -->
    <el-card>
      <div v-if="roleName == '超级管理员' || '公司管理员'">
        <el-row>
          <el-col :span="7">
            <el-input placeholder="请输入项目名称" v-model="queryInfo.query" @keyup.enter="getItemList()" clearable @clear="getItemList()">
              <template #append><el-button icon="el-icon-search" @click="getItemList()"></el-button></template>
            </el-input>
          </el-col>
          <el-col :span="14"></el-col>
          <el-col :span="3">
            <el-button style="width:100%;padding:1px" type="primary" icon="el-icon-plus" @click="addDialogVisible=true">添加</el-button>
          </el-col>
        </el-row>
        <!-- 列表区域 -->
        <el-table :data="itemList" stripe border style="width: 100%">
          <el-table-column type="index" label="序号"></el-table-column>
          <el-table-column label="项目名称" prop="itemName"></el-table-column>
          <el-table-column label="项目负责人" prop="itemAdminPhone"></el-table-column>
          <!-- 项目没有状态 无需注销 -->
          <el-table-column label="操作">
            <template v-slot="scope">
              <el-button size="mini" type="primary" icon="el-icon-delete" @click="SkanItemList(scope.row)">查看项目成员列表</el-button>
            </template>
          </el-table-column>
        </el-table>
        <!-- 分页 区域 -->
        <el-config-provider :locale="locale">
          <el-pagination
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            :current-page="queryInfo.pagenum"
            :page-sizes="[1, 2, 5, 10]"
            :page-size="queryInfo.pagesize"
            layout="total, sizes, prev, pager, next, jumper"
            :total="total"
          ></el-pagination>
        </el-config-provider>

        <!-- 项目成员 -->
        <div v-if="isSkan == true" style="margin-top: 25px;">
        <span>项目成员</span>
        <el-button type="warning" round @click="CloseButton">关闭</el-button>
        <el-table :data="simpleItemList" stripe border style="width: 100%">
          <el-table-column type="index" label="序号"></el-table-column>
          <el-table-column label="项目成员" prop="phone"></el-table-column>
          <el-table-column label="所在公司" prop="companyName" v-slot="scope">{{scope.row.companyName == null ? '尚为加入任何公司':scope.row.companyName}}</el-table-column>
          <el-table-column label="成员岗位" prop="roleName"></el-table-column>
        </el-table>
        <!-- 分页 区域 -->
        <el-config-provider :locale="locale">
          <el-pagination
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            :current-page="itemQuery.pagenum"
            :page-sizes="[1, 2, 5, 10]"
            :page-size="itemQuery.pagesize"
            layout="total, sizes, prev, pager, next, jumper"
            :total="total"
          ></el-pagination>
        </el-config-provider>
      </div>
      </div>
    </el-card>

    <el-dialog v-model="addDialogVisible" title="添加项目" width="50%" @closed="closedAddForm()">
      <el-form :model="addForm" :rules="rules" ref="addForm" label-width="100px">
        <el-form-item label="项目名称" prop="itemName">
          <el-input v-model="addForm.itemName"></el-input>
        </el-form-item>
        <el-form-item label="项目负责人" prop="userId">
          <!-- 项目负责人可以从 属于该公司且岗位为项目负责人的人中选择 以下拉框-->
          <el-select v-model="addForm.userId" placeholder="请选择项目负责人" clearable filterable style="width:100%" no-match-text="无匹配成员" >
            <el-option v-for="item in memberList" :key="item.id" :label="item.phone" :value="item.id" ></el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <!-- 底部 -->
      <template #footer>
        <span class="dialog-footer">
          <el-button @click="addDialogVisible = false">取消</el-button>
          <el-button type="primary" @click="addItem('addForm')">确定</el-button>
        </span>
      </template>
    </el-dialog>
  </div>
</template>

<script>
import zhCn from "element-plus/lib/locale/lang/zh-cn";
import global_ from '../../components/global.vue'
export default {
  data(){
    const validateName = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请输入项目名称"));
      } else {
        callback();
      }
    };
    return{
      roleName:"",
      simpleItemList:[],
      isSkan:false,
      queryInfo:{
        query:"",
        pagenum: 1,
        pagesize: 5
      },
      itemQuery:{
        query:"",
        pagenum: 1,
        pagesize: 5
      },
      itemList:[],
      memberList:[],
      total:0,
      addDialogVisible:false,
      addForm:{
        itemName:""
      },
      rules:{
        itemName: [{ validator: validateName, trigger: "blur" }],
      },
    };
  },
  setup(){
    return{
      locale:zhCn
    }
  },
  mounted(){
    this.roleName = window.sessionStorage.getItem('roleName')
    this.getItemList();this.getMemberList()
  },
  methods:{
     async getItemList() {
      this.queryInfo.curCompanyId = window.sessionStorage.getItem("curCompanyId")
      const { data: res } = await this.$http.get("companyItem/getItemList", {params: this.queryInfo});
      if (res.code !== 200)
        return this.$message.error(res.msg);
      console.log(res);
      this.itemList = res.data.list;
      this.total = res.data.total;
    },
    async SkanItemList(scope) {
      this.isSkan = true
      this.itemQuery.curItemId = scope.itemId;
      const{data:res} = await this.$http.get("/companyItem/getItemMemberList",{params:this.itemQuery});
      if(res.code != 200) return this.$message.error(res.msg)
      this.simpleItemList = res.data.list.slice(1);
      this.total = res.data.total;
    },
    async getMemberList(){
      this.queryInfo.curCompanyId = window.sessionStorage.getItem("curCompanyId");
      const { data: res } = await this.$http.get("user/getItemAdmin",{params:{curCompanyId:this.queryInfo.curCompanyId}});
      if (res.code !== 200)
        return this.$message.error(res.msg);
      this.memberList = res.data;
    },
    CloseButton() {
      this.isSkan = false;
    },
    handleSizeChange(newSize) {
      this.queryInfo.pagesize = newSize;
      this.getItemList();
    },
    handleCurrentChange(newPage) {
      this.queryInfo.pagenum = newPage;
      this.getItemList();
    },
    closedAddForm(){
      this.$refs.addForm.resetFields()
    },
    addItem(form) {
      this.$refs[form].validate(async valid => {
        if (!valid) { return;}
        this.addDialogVisible = false;
        let formData = new FormData();
        formData.append('curCompanyId',window.sessionStorage.getItem("curCompanyId"))
        formData.append('itemName',this.addForm.itemName)
        formData.append('addUserId',this.addForm.userId)
        const { data: res } = await this.$http.post("companyItem/addItem",formData);
        if (res.code !== 200){ return this.$message.error(res.msg);}
        this.$message.success(res.msg);
        this.getItemList();
      });
    },
  }
}
</script>

<style lang="less" scoped>

</style>
